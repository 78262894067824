import { Consumption, ConsumptionDetail } from './../../models/consumption.model';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import dayjs from 'dayjs';

import { BehaviorSubject, Observable, map, from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConsumptionService {

  private usageRequests: AngularFirestoreCollection<any>;
  private consumptionDetails: AngularFirestoreCollection<any>;
  private clients: AngularFirestoreCollection<any>;

  constructor(private afs: AngularFirestore) {
    this.usageRequests = this.afs.collection<any>('usages');
    this.consumptionDetails = this.afs.collection<any>('consumption_details');
    this.clients = this.afs.collection<any>('clients');
  }

  order = new BehaviorSubject<any>('');

  //crea un nuevo consumos en la coleccion de usages
  async createConsumption(consumption: Consumption) {
    try {
      await this.usageRequests.doc(this.afs.createId()).set(consumption);
      console.log('El objeto se ha creado correctamente.');
    } catch (error) {
      console.error('Error al crear el objeto:', error);
    }
  }

  getAllConsumptions(): Observable<any> {
    return this.usageRequests.valueChanges({ idField: 'id' });
  }

  getConsumptionDetailById(consumptionId: string): Observable<any> {
    // Utiliza el método doc() para obtener una referencia al documento específico por su ID
    const consumptionRef = this.consumptionDetails.doc(consumptionId);
    // Utiliza el método valueChanges() para obtener un Observable del documento
    return consumptionRef.valueChanges();
  }

  // getAllConsumptionsRange(dateStart: any, dateEnd: any): Observable<any> {
  //   const start = dayjs(dateStart).startOf('day').toDate();
  //   const end = dayjs(dateEnd).endOf('day').toDate();

  //   return this.afs
  //     .collection('usages', (ref) => ref
  //       .where('dateCreation', '>=', start)
  //       .where('dateCreation', '<=', end)
  //     )
  //     .valueChanges({ idField: 'id' });
  // }

  getAllConsumptionsRange(clientId: string, dateStart: any, dateEnd: any): Observable<any[]> {
    const start = dayjs(dateStart).startOf('day').toDate();
    const end = dayjs(dateEnd).endOf('day').toDate();

    return this.afs
      .collection('ordered_viandas', (ref) =>
        ref
          .where('company.companyId', '==', clientId)
          .where('date_meal', '>=', start)
          .where('date_meal', '<=', end)
      )
      .snapshotChanges()
      .pipe(
        map((snaps: any[]) =>
          snaps.map((snap) => {
            const id = snap.payload.doc.id;
            const data = snap.payload.doc.data();
            return { id, ...data }; //agrega el id para hacer los filtros
          })
        )
      );
  }

  getAllConsumptionsDetailRange(dateStart: any, dateEnd: any): Observable<any> {
    const start = dayjs(dateStart).startOf('day').toDate();
    const end = dayjs(dateEnd).endOf('day').toDate();

    return this.afs
      .collection('consumption_details', (ref) => ref
        .where('dateCreation', '>=', start)
        .where('dateCreation', '<=', end)
      )
      .valueChanges({ idField: 'id' });
  }

  changeMultipleUsagesStatus(usages: any, status: string) {
    usages.forEach((usage:any) => {
      usage.status = status;
      this.usageRequests.doc(usage.id).set(usage).then(() => {

      })
        .catch((error) => {
          console.error('Error al actualizar el objeto:', error);
        });
    });
  }

  changeMultipleUsagesStatuss(usages: any[], status: string) {
    const batch = this.afs.firestore.batch();

    usages.forEach(usage => {
      const docRef = this.usageRequests.doc(usage.id);
      batch.update(docRef.ref, { status: status });
    });

    return batch.commit().then(() => {

    }).catch((error) => {
      console.error('Error al actualizar objetos en lote:', error);
    });
  }

  //crea un nuevo consumos en la coleccion de usages
  async createConsumptionDetail(consumption: ConsumptionDetail) {
    try {
      await this.consumptionDetails.doc(this.afs.createId()).set(consumption);
  
    } catch (error) {
      console.error('Error al crear el objeto:', error);
    }
  }

  async changeStatusConsumptionDetail(objetoId: string, nuevoValor: any) {
    const objetoDocRef = this.consumptionDetails.doc(objetoId).ref;
    try {
      await objetoDocRef.update({ ['status']: nuevoValor });
      
    } catch (error) {
      console.error('Error al eliminar el Detalle de consumo:', error);
    }
  }

  async updateConsumptionDetail(objetoId: string, nuevoValor: any, attribute: string) {
    const objetoDocRef = this.consumptionDetails.doc(objetoId).ref;
    try {
      await objetoDocRef.update({ [attribute]: nuevoValor });
      
    } catch (error) {
      console.error('Error al eliminar el Detalle de consumo:', error);
    }
  }

  searchObjectBy(atributo1: string, valor1: any, atributo2: string, valor2: any): Observable<any[]> {
    return from(
      this.consumptionDetails.ref.where(atributo1, '==', valor1).where(atributo2, '==', valor2).get()
    ).pipe(
      map((querySnapshot) => {
        const objects: any[] = [];
        querySnapshot.forEach((doc) => {
          const dataWithId = { id: doc.id, ...doc.data() };
          objects.push(dataWithId);
        });
        return objects;
      })
    );
  }

  changeConsumptionDeatilStatus(newConsumption: any) {
    this.consumptionDetails.doc(newConsumption.id).set(newConsumption).then(() => {
     
    })
      .catch((error) => {
        console.error('Error al actualizar el detalle de consumo:', error);
      });
  }

  async getSocialReasons(idClient: string): Promise<any>  {
    const snapshot = await this.clients.doc(idClient).get().toPromise();
    if (snapshot && snapshot.exists) {
      return snapshot.get('razones_sociales');
    }
    return null;  
  }
  

  generateViandasConsumptionData(pedidoEntrega:any, status:any){
    pedidoEntrega = JSON.parse(JSON.stringify(pedidoEntrega));

    const consumption: Consumption = {
      numberReceipt: pedidoEntrega.number,
      dateEdit: new Date(),
      dateCreation: new Date(),
      client: pedidoEntrega.companyName,
      clientId: pedidoEntrega.companyId,
      location: pedidoEntrega.location,
      service: pedidoEntrega.service,
      amount: pedidoEntrega.mealPrice,
      iva: {},
      items: pedidoEntrega.items,
      status: status,
      viandas_quantity: pedidoEntrega.menus_quantity,
    }

    return consumption
  }
}
