import { Component, ViewEncapsulation } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SharedModule } from './shared/shared.module';
import { DateAdapter } from '@angular/material/core';
import { localeEs } from "./shared/utils/primeng-locale";
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, SharedModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  constructor(private configPrime: PrimeNGConfig) {}

  ngOnInit() {
    this.configPrime.setTranslation(localeEs);
  }
  
  title = 'interno';

}
