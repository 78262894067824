import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  DocumentData,
  DocumentSnapshot,
} from '@angular/fire/compat/firestore';
import {
  collection,
  getDocs,
  query,
  Timestamp,
  where,
} from '@angular/fire/firestore';
import { Observable, merge, empty, of } from 'rxjs';
import { DeliveryOrder, DeliveryOrderTableData } from '../../models/delivery_orders.model';
import firebase from 'firebase/compat/app';
import { catchError, onErrorResumeNext, take } from 'rxjs/operators';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { ViandasService } from './viandas.service';
import { deliveryStatus, Services, viandaStatus } from '../../models/enums';//src/app/models/enums
import dayjs from 'dayjs';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root',
})
export class DeliveryService {
  private deliveryRequests: AngularFirestoreCollection<any>;
  private utilsCollection: AngularFirestoreCollection<any>;

  constructor(
    private afs: AngularFirestore,
    private viandaServ: ViandasService
  ) {
    this.deliveryRequests = this.afs.collection<any>('delivery_orders');
    this.utilsCollection = this.afs.collection<any>('utils');
    //this.viandasCollection = this.afs.collection<any>('');
    //this.suppliesCollection = this.afs.collection<any>('');
  }

  /**
   * funciones para hacer
   * getAllDeliveryOrders() --> recupera todas las colecciones de delivery
   * getDeliveryByDate(date) --> recupera las colecciones de delivery con la misma fecha
   * getDeliveryByStatus(status) --> recupera las colecciones de delivery con el mismo estado
   * saveDeliveryChanges(newDeliveryOrder)--> guarda los cambios de una colección (sirve para cuando cambia de estado o si ya se entregó comprobante)
   */

  incrementDeliveryQuantity() {
    const db = firebase.firestore();
    const increment = firebase.firestore.FieldValue.increment(1);
    const storyRef = db.collection('utils').doc('delivery_counter');
    storyRef.update({ count: increment });
  }

  getDeliveryCount() {
    const db = firebase.firestore();
    return this.utilsCollection
      .doc('delivery_counter')
      .valueChanges()
      .pipe(take(1)) // Utilizamos el operador 'take(1)' para completar el observable después de recibir el primer valor
      .toPromise() // Convertimos el observable en una promesa
      .then((doc: any) => {
        return doc;
      }); // Accedemos al campo 'count' del documento
  }

  getAllDeliveryOrders(): Observable<any> {
    return this.afs
      .collection('delivery_orders')
      .valueChanges({ idField: 'id' });
  }

  createDeliveryOrder(deliveryOrder: DeliveryOrder, deliveryId: string) {
    return this.deliveryRequests.doc(deliveryId).set(deliveryOrder);
  }

  getDeliveryByStatus(status: deliveryStatus) {
    return this.afs
      .collection('delivery_orders', (ref) => ref.where('status', '==', status))
      .valueChanges({ idField: 'id' });
  }

  //recupera las colecciones de delivery con el mismo destino
  getDeliveryByLocation(location: any, date: any): Promise<any> {
    return this.afs
      .collection('delivery_orders', (ref) =>
        ref
          .where('location.id', '==', location.id)
          .where('delivered_date', '==', date)
      )
      .get()
      .toPromise()
      .then((querySnapshot) => {
        if (!querySnapshot?.empty) {
          const doc = querySnapshot?.docs[0];
          const data = doc?.data();
          return {
            id: doc?.id,
            data: data ? data : {},
          };
        } else {
          return null;
        }
      });
  }

  async getDeliveryByLocationDateClient(clientId: string, locationId: string, deliveryDate: any) {
    let date = this.convertDate(deliveryDate);
    const startOfDateToSearch = dayjs(date).startOf('day').toDate();
    const endOfDateToSearch = dayjs(date).endOf('day').toDate();

    const startOfDayTimestamp = Timestamp.fromDate(startOfDateToSearch);
    const endOfDayTimestamp = Timestamp.fromDate(endOfDateToSearch);

    try {
      const q = query(
        collection(this.afs.firestore, 'delivery_orders'),
        where('delivered_date', '>=', startOfDayTimestamp),
        where('delivered_date', '<=', endOfDayTimestamp),
        where('client.companyId', '==', clientId),
        where('location.id', '==', locationId),
        where('status', '==', 'pendiente')
      );

      const querySnapshot = await getDocs(q);
      const docs = await this.buildArrayOfDocs(querySnapshot);
      return docs;
    } catch (error) {
      console.error('Error getting documents: ', error);
      throw error;
    }
  }

  /*  convertDate(date: any) {
    return date.seconds * 1000 + date.nanoseconds / 1000000;
  } */
  private convertDate(date: any): Date {
    if (date instanceof Date) {
      return date;
    } else if (typeof date === 'string' || typeof date === 'number') {
      return new Date(date);
    } else if (date.seconds !== undefined && date.nanoseconds !== undefined) {
      return new Date(date.seconds * 1000 + date.nanoseconds / 1000000);
    } else {
      throw new Error('Formato de fecha no reconocido');
    }
  }

  private async buildArrayOfDocs(docs: any): Promise<any[]> {
    const arrayOfDocs: any[] = [];
    docs.forEach((doc: any) => {
      arrayOfDocs.push({ order: doc.data(), orderId: doc.id });
    });
    return arrayOfDocs;
  }

  async addItemToDelivery(item: any, service: any): Promise<void> {

    try {
      const existingOrders = await this.getDeliveryByLocationDateClient(
        item.company.companyId,
        item.location.id,
        this.convertDate(item.date_meal)
      );

      item.service = service

      if (existingOrders.length > 0) {
        await this.updateExistingOrder(existingOrders[0], item);
      } else {

        await this.createAndSaveNewOrder(item);
      }
    } catch (error) {
      console.error('Error al gestionar la entrega:', error);
    }
  }

  private async createAndSaveNewOrder(item: any): Promise<void> {
    try {
      const deliveryCount = await this.getDeliveryCount();
      const newDeliveryOrder: DeliveryOrder = this.createDeliveryOrderObject(
        item,
        deliveryCount.count + 1
      );

      const deliveryId = this.afs.createId();
      await this.createDeliveryOrder(newDeliveryOrder, deliveryId);
      await this.incrementDeliveryQuantity();

      item.delivery_order_id = deliveryId;
      await this.viandaServ.orderMealSaveChange(item.id, item);
    } catch (error) {
      console.error('Error creando la nueva orden de entrega:', error);
    }
  }
  private createDeliveryOrderObject(
    item: any,
    orderNumber: number
  ): DeliveryOrder {
    return {
      number: orderNumber,
      receipt_generated: false,
      date_creation: item.date_request,
      status: 'pendiente',
      client: {
        companyId: item.company.companyId,
        companyName: item.company.companyName,
        contractId: item.company.contractId,
      },
      items: [item],
      location: {
        address: item.location.address,
        coordinates: {
          latitude: item.location.coordinates.latitude,
          longitude: item.location.coordinates.longitude,
        },
        id: item.location.id,
        name: item.location.name,
        observation: item.location.observation,
        sucursal: null,
      },
      delivered_date: item.date_meal,
      delivered_by: null,
      delivered_receipt: '',
    };
  }

  editItemDelivery(item: any, itemID: string, oeID: string): void {
    this.getDeliveryById(oeID)
      .pipe(
        catchError((error) => of(`Error al obtener la orden de entrega: ${error}`)),
        take(1))
      .subscribe(async (existingOrder: any) => {
        if (existingOrder) {
          const items = existingOrder.items;
          const itemIndex = items.findIndex((i: any) => i.id === itemID);
          if (itemIndex !== -1) {
            switch (item.status_request) {
              case viandaStatus.Cancelado:
                items.splice(itemIndex, 1);
                if (items.length == 0) {
                  existingOrder.status = deliveryStatus.Cancelado;
                }
                item.delivery_order_id = '';
                await this.viandaServ.orderMealSaveChange(item.id, item);
                break;
              case viandaStatus.Aceptado:
                items[itemIndex] = item;

                break;
              default:
                console.log('Estado del item no modificado.');
                break;
            }

            try {
              await this.saveDeliveryOrderbyId(existingOrder, oeID);
            } catch (error) {
              console.error('Error actualizando la orden de entrega:', error);
            }
          } else {
            console.error('Item no encontrado en la orden de entrega.');
          }
        } else {
          console.error('Orden de entrega no encontrada.');
        }
      })
  }

  private async updateExistingOrder(
    existingOrder: any,
    item: any
  ): Promise<void> {
    const orderId = existingOrder.orderId;
    const updatedOrder = existingOrder.order;

    updatedOrder.items.push(item);

    try {
      await this.saveDeliveryOrderbyId(updatedOrder, orderId);
      item.delivery_order_id = orderId;
      await this.viandaServ.orderMealSaveChange(item.id, item);
    } catch (error) {
      console.error('Error actualizando la orden:', error);
    }
  }

  getDeliveryByViandaId(id: string) {
    return this.afs
      .collection('delivery_orders')
      .doc(id)
      .valueChanges({ idField: 'id' });
  }

  getDeliveryById(id: string) {
    return this.afs
      .collection('delivery_orders')
      .doc(id)
      .valueChanges({ idField: 'id' });
  }

  getDeliveryOrdersDate(date: any): Observable<any> {
    const dateEnd = dayjs(date).endOf('day').toDate();
    return this.afs
      .collection('delivery_orders', (ref) =>
        ref
          .where('delivered_date', '>=', date)
          .where('delivered_date', '<=', dateEnd)
      )
      .valueChanges({ idField: 'id' });
  }


  saveDeliveryOrder(newOrder: any, order: any): Promise<any> {
    const orderRef = this.afs.collection('delivery_orders').doc(`${order.id}`);
    return orderRef.set(newOrder, { merge: true });
  }

  saveDeliveryOrderbyId(newOrder: any, id: string): Promise<any> {
    const orderRef = this.afs.collection('delivery_orders').doc(`${id}`);
    return orderRef.set(newOrder, { merge: true });
  }


  changeItemStatus(deliveryId: string, itemId: string, action: string): any {
    const orderRef = this.afs
      .collection('delivery_orders')
      .doc(`${deliveryId}`);
    return orderRef
      .get()
      .toPromise()
      .then((doc: any) => {
        if (doc.exists && doc.data()['status'] === 'entregado') {
          return Promise.resolve(); // El estado es 'entregado', no es necesario modificarlo.
        } else {
          return orderRef.set({ status: action }, { merge: true }); // El estado no es 'entregado', modificarlo.
        }
      })
      .catch((error) => {
        console.error('Error getting document:', error);
        return Promise.reject(error);
      });
  }

  changeStatusDelivery(action: any, orderId: any): Promise<any> {
    const orderRef = this.afs.collection('delivery_orders').doc(`${orderId}`);
    return orderRef
      .get()
      .toPromise()
      .then((doc: any) => {
        if (doc.exists) {
          const data = doc.data();
          if (data && data['status'] === 'entregado') {
            return Promise.resolve(); // El estado es 'entregado', no es necesario modificarlo.
          } else {
            return orderRef.set({ status: action }, { merge: true }); // El estado no es 'entregado', modificarlo.
          }
        } else {
          // Maneja el caso en el que el documento no exista
          return Promise.reject(new Error('El documento no existe'));
        }
      })
      .catch((error) => {
        console.error('Error al obtener el documento:', error);
        return Promise.reject(error);
      });
  }

  async changeDeliveryPersonMultipleDeliveryOrders(
    name: string,
    orderIds: string[]
  ): Promise<any> {
    const collectionRef = this.afs.collection('delivery_orders');

    const batch = this.afs.firestore.batch();

    const getDocumentPromises = orderIds.map((orderId) =>
      collectionRef.doc(orderId).ref.get()
    );

    const snapshots = await Promise.all(getDocumentPromises);

    snapshots.forEach((snapshot) => {
      const data = snapshot.data() as DocumentData; // Indicar el tipo de datos como DocumentData

      const orderRef = snapshot.ref;
      batch.set(orderRef, { delivered_by: name }, { merge: true });
    });

    return batch.commit();
  }

  async changeStatusMultipleDeliveryOrders(
    action: string,
    orderIds: string[]
  ): Promise<any> {
    const collectionRef = this.afs.collection('delivery_orders');

    const batch = this.afs.firestore.batch();

    const getDocumentPromises = orderIds.map((orderId) =>
      collectionRef.doc(orderId).ref.get()
    );

    const snapshots = await Promise.all(getDocumentPromises);

    snapshots.forEach((snapshot) => {
      const data = snapshot.data() as DocumentData; // Indicar el tipo de datos como DocumentData

      if (snapshot.exists && data['status'] !== 'entregado') {
        const orderRef = snapshot.ref;
        batch.set(orderRef, { status: action }, { merge: true });
      }
    });
    return batch.commit();
  }

  getRepartidoresList() {
    return this.afs
      .collection('users', (ref) => ref.where('role', '==', 'Delivery'))
      .valueChanges({ idField: 'id' });
  }

  //poner esto en un adapter

  callDeliveryFunction(functionName: any) {
    return;
  }

  deliverOrder(pedidoEntrega: any): any {
    let error;
    if (pedidoEntrega.delivered_by != '') {
      pedidoEntrega.ordersId.forEach((orderId: any) => {
        this.changeStatusDelivery('viajando', orderId).then((res) => {
          return true;
          // this.dataUpdated.emit(true);
        });
        error = false;
        return true
      });
    } else {
      error = true;
      return false;
      // this.openDialogError(error);
    }
  }

  /** FUNCIONES QUE ORGANIZAN DATOS PARA TABLAS */


  joinOrderMenus(vianda: any, joinedMenus: any) {
    vianda.menus_requested.forEach((menu: any) => {

      let clonedMenu = { ...menu } //clona el menu para no modificar los menus del item original

      let menuIdx = joinedMenus.array.findIndex((joinedMenu: any) => joinedMenu.typeMenu.component === clonedMenu.typeMenu.component
        && joinedMenu.typeMenu.menu_name === clonedMenu.typeMenu.menu_name)
      if (menuIdx != -1) {
        joinedMenus.array[menuIdx].quantity += clonedMenu.quantity
      } else {
        joinedMenus.array.push(clonedMenu)
      }
      if (menu.typeMenu.component === 'Principal') {
        joinedMenus.quantity += clonedMenu.quantity
      }

    });
    return joinedMenus
  }

  joinOrderDrinks(vianda: any, joinedDrinks: any) {
    vianda.drinks_requested.forEach((drink: any) => {

      let clonedDrink = { ...drink } //clona la bebida para no modificar las bebidas del item original

      let drinkIdx = joinedDrinks.array.findIndex((joinedDrink: any) => joinedDrink.drink.id === clonedDrink.drink.id)
      if (drinkIdx != -1) {
        joinedDrinks.array[drinkIdx].quantity += clonedDrink.quantity
      } else {
        joinedDrinks.array.push(clonedDrink)
      }
      joinedDrinks.quantity += clonedDrink.quantity
    });
    return joinedDrinks
  }

  filterViandasFromItems(oe: DeliveryOrder) {
    let viandas: Array<any> = []
    oe.items.forEach((item: any) => {
      if (item.service === Services.Viandas) {
        viandas.push({ ...item })
      }
    })
    return viandas
  }

  setDeliveryTableData(orders: Array<any>) {
    let tableData: Array<DeliveryOrderTableData> = []

    orders.forEach(oe => {
      let menus = {
        array: [],
        quantity: 0
      }
      let drinks = {
        array: [],
        quantity: 0
      }
      let viandas = this.filterViandasFromItems(oe)
      viandas.forEach(vianda => {
        menus = this.joinOrderMenus({ ...vianda }, menus);
        drinks = this.joinOrderDrinks({ ...vianda }, drinks)
      });
      let orderTableData: DeliveryOrderTableData = {
        id: oe.id,
        number: oe.number,
        companyName: oe.client.companyName,
        companyId: oe.client.companyId,
        location: oe.location.name,
        locationId: oe.location.id,
        address: oe.location.address,
        delivered_date: oe.delivered_date,
        menus_quantity: menus.quantity,
        drinks_quantity: drinks.quantity,
        menus_requested: menus.array,
        drinks_requested: drinks.array,
        items: oe.items,
        delivered_by: oe.delivered_by,
        delivered_receipt: oe.delivered_receipt,
        mealPrice: oe.items.length > 0 ? oe.items[0].mealPrice : 0,
        service: Services.Viandas,
        status: oe.status
      }

      tableData.push(orderTableData)
    });

    return tableData

  }

  /********* FIN FUNCIONES PARA TABLAS */

  async generateDeliveryDetailPdf(pdfData: any) {
    var pdfDoc: TDocumentDefinitions = {
      footer: function (currentPage: any, pageCount: any) {
        return {
          text: 'Pág. ' + currentPage.toString() + '/' + pageCount,
          alignment: 'right',
          margin: [0, 0, 20, 0],
          fontSize: 9,
        };
      },
      content: [
        {
          style: 'dateAndLogoTable',
          table: {
            widths: [60, '*', '*'],
            body: [
              [
                {
                  image: await this.getBase64ImageFromURL(
                    '../../../../../assets/images/butaco_logo_short.svg'
                  ),
                  fit: [50, 50],
                  width: 100,
                  rowSpan: 2,
                },
                {
                  text: 'BUTACO S.R.L', style: 'headerFont'
                },
                {

                  text: 'DETALLE ORDEN DE ENTREGA',
                  style: 'headerFont',
                  alignment: 'right',
                },
              ],
              [
                {},
                {
                  layout: 'noBorders',
                  table: {
                    body: [
                      [{ text: 'Dirección', style: 'tableData' }, { text: 'Obrero Argentino 1101', style: 'tableData' }],
                      [{ text: 'Ciudad', style: 'tableData' }, { text: 'Neuquén CP 8300', style: 'tableData' }]
                    ]

                  },
                  margin: [0, 0, 0, 20]
                },
                {
                  text: pdfData.deliveryNumber,
                  // 0001-00000001
                  style: 'headerFont',
                  alignment: 'right',
                },
              ],
            ],
          },
          layout: {
            hLineWidth: function (i: any, node: any) {
              if (i === node.table.body.length) {
                return 0.5;
              }
              return 0;
            },
            vLineWidth: function (i: any, node: any) {
              return 0;
            },
            hLineColor: function (i: any, node: any) {
              return '#979797';
            },
          },
        },
        {
          style: 'dateAndLogoTable',
          table: {
            widths: ['*', '*'],
            body: [
              [
                {
                  text: 'INFORMACIÓN',
                  style: 'titleFont'
                },
                {
                  text: 'Fecha: ' + pdfData.deliveryDate,
                  style: 'receiptDate',
                  alignment: 'right',
                  margin: [0, 0, 0, 7],
                },
              ],
              [
                {
                  layout: 'noBorders',
                  table: {
                    body: [
                      [{ text: 'Cliente', style: 'tableData' }, { text: pdfData.client.name, style: 'clientData' }],
                      [{ text: 'Ubicación', style: 'tableData' }, { text: pdfData.client.location, style: 'clientData' }]
                    ]

                  },
                  margin: [0, 0, 0, 10],
                },
                {

                },
              ],
            ],

          },
          layout: {
            hLineWidth: function (i: any, node: any) {
              if (i === node.table.body.length) {
                return 0.5;
              }
              return 0;
            },
            vLineWidth: function (i: any, node: any) {
              return 0;
            },
            hLineColor: function (i: any, node: any) {
              return '#979797';
            },
          },
        },
        {
          table: {
            widths: ['*', '*', '*'],
            body: [
              [{ text: 'DETALLE', style: 'titleFont', margin: [0, 0, 0, 7] },
              {},
              {}],
              ...(await this.generateDetailTableRows(
                pdfData.detailArray
              )),
            ]
          },
          layout: {
            hLineWidth: function (i: any, node: any) {
              if (i > 1) {
                return 0.5;
              }
              return 0;
            },
            vLineWidth: function (i: any, node: any) {
              return 0;
            },
            hLineColor: function (i: any, node: any) {
              return '#979797';
            },
          }
        }
      ],
      styles: {
        dateAndLogoTable: {
          margin: [0, 0, 0, 10],
        },
        headerFont: {
          fontSize: 12,
          bold: true,
        },
        receiptDate: {
          fontSize: 9,
          bold: true
        },
        tableData: {
          fontSize: 9,
          bold: false,
          lineHeight: 1.2
        },
        titleFont: {
          fontSize: 10,
          color: '#64886A',
          bold: true
        },
        clientData: {
          fontSize: 9,
          bold: true
        },
      },

    }

    pdfMake.createPdf(pdfDoc).open();
  }

  //se genera el array de la tabla de items apta para pdfMake
  async generateDetailTableRows(items: any) {
    let orderData: Array<any> = [];
    items.forEach((item: any) => {
      let row: Array<any> = [
        { text: item.item, style: 'tableData' },
        { text: item.item_type, style: 'tableData', alignment: 'center' },
        { text: item.quantity, style: 'tableData', alignment: 'right', margin: [0, 0, 5, 0] },
      ];
      orderData.push(row);
    });
    return orderData;
  }


  async generateDeliveryReceiptPdf(pdfData: any) {

    var pdfDoc: TDocumentDefinitions = {
      content: [
        {
          style: 'dateAndLogoTable',
          table: {
            widths: [60, '*', '*'],
            body: [
              [
                {
                  image: await this.getBase64ImageFromURL(
                    '../../../../../assets/images/butaco_logo_short.svg'
                  ),
                  fit: [50, 50],
                  width: 100,
                  rowSpan: 2,
                },
                {
                  text: 'BUTACO S.R.L', style: 'headerFont'
                },
                {

                  text: 'ORDEN ENTREGA ' + pdfData.deliveryNumber,
                  style: 'headerFont',
                  alignment: 'right',
                },
              ],
              [
                {},
                {
                  layout: 'noBorders',
                  table: {
                    body: [
                      [{ text: 'Dirección', style: 'tableData' }, { text: 'Obrero Argentino 1101', style: 'tableData' }],
                      [{ text: 'Ciudad', style: 'tableData' }, { text: 'Neuquén CP 8300', style: 'tableData' }]
                    ]

                  },
                  margin: [0, 0, 0, 20]
                },
                {
                  columns: [
                    { text: 'CUIT', alignment: 'right', style: 'tableData' },
                    { text: '30-71228256-4', alignment: 'right', style: 'tableData' }
                  ]
                },
              ],
            ],
          },
          layout: {
            hLineWidth: function (i: any, node: any) {
              if (i === node.table.body.length) {
                return 0.5;
              }
              return 0;
            },
            vLineWidth: function (i: any, node: any) {
              return 0;
            },
            hLineColor: function (i: any, node: any) {
              return '#979797'; // Líneas finas y grises debajo de los demás rows
            },
          },
        },
        {
          style: 'dateAndLogoTable',
          table: {
            widths: ['*', '*'],
            body: [
              [
                {
                  text: 'INFORMACIÓN',
                  style: 'titleFont'
                },
                {
                  text: 'Periodo: ' + pdfData.deliveryDate,
                  style: 'receiptDate',
                  alignment: 'right',
                  margin: [0, 0, 0, 10],
                },
              ],
              [
                {
                  layout: 'noBorders',
                  table: {
                    body: [
                      [{ text: 'Cliente', style: 'tableData' }, { text: pdfData.client.name, style: 'clientData' }],
                      [{ text: 'Ubicación', style: 'tableData' }, { text: pdfData.client.location, style: 'clientData' }]
                    ]

                  },
                  margin: [0, 0, 0, 10],
                },
                {

                },
              ],
            ],

          },
          layout: {
            hLineWidth: function (i: any, node: any) {
              if (i === node.table.body.length) {
                return 0.5;
              }
              return 0;
            },
            vLineWidth: function (i: any, node: any) {
              return 0;
            },
            hLineColor: function (i: any, node: any) {
              return '#979797'; // Líneas finas y grises debajo de los demás rows
            },
          },
        },
        {
          style: 'descriptionTable',
          table: {
            widths: ['*', '*'],
            body: [
              [
                {
                  text: 'DESCRIPCIÓN',
                  style: 'titleFont'
                },
                {

                }

              ],
              [
                {},
                { text: 'Cant', style: 'tableData', alignment: 'right' }
              ],
              ...(await this.generateServicesRows(
                pdfData.services
              )),
            ],

          },
          layout: {
            hLineWidth: function (i: any, node: any) {
              if (i === node.table.body.length) {
                return 0.5;
              }
              return 0;
            },
            vLineWidth: function (i: any, node: any) {
              return 0;
            },
            hLineColor: function (i: any, node: any) {
              return '#979797'; // Líneas finas y grises debajo de los demás rows
            },
          },
        },
        {
          style: 'signTable',
          table: {
            widths: ['*', 25, 100, 30, 45, 100],
            body: [
              [
                { text: '', margin: [0, 0, 0, 10] },
                { text: 'Firma', style: 'tableData', margin: [0, 0, 0, 10] },
                { text: '__________________', margin: [0, 0, 0, 10] },
                { text: '', margin: [0, 0, 0, 10] },
                { text: 'Aclaración', style: 'tableData', margin: [0, 0, 0, 10] },
                { text: '__________________', margin: [0, 0, 0, 10] },
              ],
            ],
          },
          layout: {
            hLineWidth: function (i: any, node: any) {
              if (i === node.table.body.length) {
                return 0.5;
              }
              return 0;
            },
            vLineWidth: function (i: any, node: any) {
              return 0;
            },
            hLineColor: function (i: any, node: any) {
              return '#979797'; // Líneas finas y grises debajo de los demás rows
            },
            hLineStyle: function (i: any, node: any) {
              if (i === node.table.body.length) {
                return { dash: { length: 2, space: 3 } }; // Líneas punteadas
              }
              return null;
            }
          }
        },

        {
          style: 'dateAndLogoTable',
          table: {
            widths: [60, '*', '*'],
            body: [
              [
                {
                  image: await this.getBase64ImageFromURL(
                    '../../../../../assets/images/butaco_logo_short.svg'
                  ),
                  fit: [50, 50],
                  width: 100,
                  rowSpan: 2,
                },
                {
                  text: 'BUTACO S.R.L', style: 'headerFont'
                },
                {

                  text: 'ORDEN ENTREGA ' + pdfData.deliveryNumber,
                  style: 'headerFont',
                  alignment: 'right',
                },
              ],
              [
                {},
                {
                  layout: 'noBorders',
                  table: {
                    body: [
                      [{ text: 'Dirección', style: 'tableData' }, { text: 'Obrero Argentino 1101', style: 'tableData' }],
                      [{ text: 'Ciudad', style: 'tableData' }, { text: 'Neuquén CP 8300', style: 'tableData' }]
                    ]

                  },
                  margin: [0, 0, 0, 20]
                },
                {
                  columns: [
                    { text: 'CUIT', alignment: 'right', style: 'tableData' },
                    { text: '30-71228256-4', alignment: 'right', style: 'tableData' }
                  ]
                },
              ],
            ],
          },
          layout: {
            hLineWidth: function (i: any, node: any) {
              if (i === node.table.body.length) {
                return 0.5;
              }
              return 0;
            },
            vLineWidth: function (i: any, node: any) {
              return 0;
            },
            hLineColor: function (i: any, node: any) {
              return '#979797'; // Líneas finas y grises debajo de los demás rows
            },
          },
        },
        {
          style: 'dateAndLogoTable',
          table: {
            widths: ['*', '*'],
            body: [
              [
                {
                  text: 'INFORMACIÓN',
                  style: 'titleFont'
                },
                {
                  text: 'Periodo: ' + pdfData.deliveryDate,
                  style: 'receiptDate',
                  alignment: 'right',
                  margin: [0, 0, 0, 10],
                },
              ],
              [
                {
                  layout: 'noBorders',
                  table: {
                    body: [
                      [{ text: 'Cliente', style: 'tableData' }, { text: pdfData.client.name, style: 'clientData' }],
                      [{ text: 'Ubicación', style: 'tableData' }, { text: pdfData.client.location, style: 'clientData' }]
                    ]

                  },
                  margin: [0, 0, 0, 10],
                },
                {

                },
              ],
            ],

          },
          layout: {
            hLineWidth: function (i, node) {
              if (i === node.table.body.length) {
                return 0.5;
              }
              return 0;
            },
            vLineWidth: function (i, node) {
              return 0;
            },
            hLineColor: function (i, node) {
              return '#979797'; // Líneas finas y grises debajo de los demás rows
            },
          },
        },
        {
          style: 'descriptionTable',
          table: {
            widths: ['*', '*'],
            body: [
              [
                {
                  text: 'DESCRIPCIÓN',
                  style: 'titleFont'
                },
                {

                }

              ],
              [
                {},
                { text: 'Cant', style: 'tableData', alignment: 'right' }
              ],
              ...(await this.generateServicesRows(
                pdfData.services
              )),
            ],

          },
          layout: {
            hLineWidth: function (i: any, node: any) {
              if (i === node.table.body.length) {
                return 0.5;
              }
              return 0;
            },
            vLineWidth: function (i: any, node: any) {
              return 0;
            },
            hLineColor: function (i: any, node: any) {
              return '#979797'; // Líneas finas y grises debajo de los demás rows
            },
          },
        },
        {
          layout: 'noBorders',
          table: {
            widths: ['*', 25, 100, 30, 45, 100],
            body: [
              [
                { text: '' },
                { text: 'Firma', style: 'tableData' },
                { text: '__________________' },
                { text: '' },
                { text: 'Aclaración', style: 'tableData' },
                { text: '__________________' },
              ],
            ],
          },

        },
      ],
      styles: {
        dateAndLogoTable: {
          margin: [0, 0, 0, 10],
        },
        headerFont: {
          fontSize: 12,
          bold: true,
        },
        receiptDate: {
          fontSize: 9,
          bold: true
        },
        tableData: {
          fontSize: 9,
          bold: false,
          lineHeight: 1.2
        },
        titleFont: {
          fontSize: 10,
          color: '#64886A',
          bold: true
        },
        clientData: {
          fontSize: 9,
          bold: true
        },
        descriptionTable: {
          margin: [0, 0, 0, 120]
        },
        signTable: {
          margin: [0, 0, 0, 30]
        }
      },

    }
    //   footer: function (currentPage: any, pageCount: any) {
    //     return {
    //       text: 'Pág. ' + currentPage.toString() + '/' + pageCount,
    //       alignment: 'right',
    //       margin: [0, 0, 20, 0],
    //       fontSize: 9,
    //     };
    //   },

    //   content: [
    //     {
    //       style: 'dateAndLogoTable',
    //       table: {
    //         widths: ['*', '*'],
    //         body: [
    //           [
    //             {
    //               image: await this.getBase64ImageFromURL(
    //                 '../../../../../assets/images/butaco_logo_full.svg'
    //               ),
    //               width: 100,
    //               rowSpan: 2,
    //               margin: [0, 0, 0, 30],
    //             },
    //             {
    //               columns: [
    //                 {
    //                   text: 'Orden de entrega Nº: ',
    //                   style: 'receiptNumber',
    //                   alignment: 'right',
    //                   margin: [0, 0, 0, 5],
    //                 },
    //                 {
    //                   text: this.generateDeliveryReceiptNumber(
    //                     pedidoEntrega.number
    //                   ),
    //                   style: 'receiptNumber',
    //                   alignment: 'right',
    //                   margin: [0, 0, 0, 5],
    //                   width: 90,
    //                   bold: true,
    //                 },
    //               ],
    //             },
    //           ],
    //           [
    //             {},
    //             {
    //               text: this.secondsToFormattedDate(
    //                 pedidoEntrega.delivered_date.seconds
    //               ),
    //               style: 'receiptDate',
    //               alignment: 'right',
    //               margin: [0, 0, 0, 10],
    //             },
    //           ],
    //         ],
    //       },
    //       layout: {
    //         hLineWidth: function (i: any, node: any) {
    //           if (i === node.table.body.length) {
    //             return 0.5;
    //           }
    //           return 0;
    //         },
    //         vLineWidth: function (i: any, node: any) {
    //           return 0;
    //         },
    //         hLineColor: function (i: any, node: any) {
    //           return '#000000'; // Líneas finas y grises debajo de los demás rows
    //         },
    //       },
    //     },
    //     {
    //       style: 'clientDataTable',
    //       table: {
    //         widths: [40, '*', 60, 200],
    //         body: [
    //           [
    //             { text: 'Cliente:', style: 'tableData' },
    //             { text: pedidoEntrega.companyName, style: 'tableHeader' },
    //             { text: 'Ubicación:', style: 'tableData' },
    //             { text: pedidoEntrega.location, style: 'tableHeader' },
    //           ],
    //           [
    //             {},
    //             {},
    //             { text: 'Dirección:', style: 'tableData' },
    //             { text: pedidoEntrega.address, style: 'tableHeader' },
    //           ], //no hay address, agregarla
    //         ],
    //       },
    //       layout: 'noBorders',
    //     },
    //     {
    //       table: {
    //         widths: ['*', 100, 100, 70, 45],
    //         heights: function (row: any) {
    //           return 15;
    //         },
    //         headerRows: 1,
    //         body: [
    //           [
    //             { text: 'Solicito', style: 'tableHeader' },
    //             {
    //               text: 'Menu',
    //               style: 'tableHeader',
    //               border: [false, true, false, false],
    //             },
    //             {
    //               text: 'Cantidad',
    //               style: 'tableHeader',
    //               border: [false, true, false, false],
    //             },
    //             {
    //               text: 'Bebida',
    //               style: 'tableHeader',
    //               border: [false, true, false, false],
    //             },
    //             {
    //               text: 'Cantidad',
    //               style: 'tableHeader',
    //               border: [false, true, false, false],
    //             },
    //           ],
    //           // [{ text: 'Sandy', style: 'tableData' }, { text: 'Fideos con tuco', style: 'tableData' }, { text: '3', style: 'tableData' }, { text: 'Coca-cola', alignment: 'right', style: 'tableData' }, { text: '3', style: 'tableData' }],
    //           // [{ text: 'Sandy', style: 'tableData' }, { text: 'Fideos con tuco', style: 'tableData' }, { text: '3', style: 'tableData' }, { text: 'Coca-cola', alignment: 'right', style: 'tableData' }, { text: '3', style: 'tableData' }],
    //           // [{ text: 'Sandy', style: 'tableData' }, { text: 'Fideos con tuco', style: 'tableData' }, { text: '3', style: 'tableData' }, { text: 'Coca-cola', alignment: 'right', style: 'tableData' }, { text: '3', style: 'tableData' }],
    //           // [{}, {}, {}, {}, {}],
    //           // [{}, {}, {}, {}, {}],
    //           // [{}, {}, {}, {}, {}],
    //           ...(await this.generateMenusTableData(
    //             pedidoEntrega.items
    //           )),
    //         ],
    //       },
    //       layout: {
    //         hLineWidth: function (i: any, node: any) {
    //           if (i > node.table.body.length - 3) {
    //             return 0; // Sin línea arriba del header
    //           }
    //           return 0.5; // Líneas finas debajo de los demás rows
    //         },
    //         vLineWidth: function (i: any, node: any) {
    //           if (i === 2 || i === 4) {
    //             return 0.5; // Líneas verticales entre columnas 2-3 y 4-5
    //           }
    //           return 0; // Sin otras líneas verticales
    //         },
    //         hLineColor: function (i: any, node: any) {
    //           if (i === 0 || i === 1) {
    //             return '#000000'; // Línea negra debajo del header
    //           }
    //           return '#C4C4C4'; // Líneas finas y grises debajo de los demás rows
    //         },
    //         vLineColor: function (i: any, node: any) {
    //           return '#C4C4C4'; // Color de las líneas verticales
    //         },
    //       },
    //     },
    //     {
    //       style: 'signTable',
    //       table: {
    //         widths: ['*', '*', 50, '*'],
    //         heights: [60, 30, 10],
    //         body: [
    //           [
    //             {
    //               rowSpan: 3,
    //               qr: pedidoEntrega.id,
    //               fit: '100',
    //               margin: [0, 10],
    //             },
    //             { text: 'Entregó', style: 'tableHeader', margin: [0, 10] },
    //             { text: '', border: [false, true, false, false] },
    //             {
    //               text: 'Recibí conforme',
    //               style: 'tableHeader',
    //               margin: [0, 10],
    //             },
    //           ],
    //           [
    //             {},
    //             { text: 'Firma', style: 'signText' },
    //             { text: '', border: [false, false, false, false] },
    //             { text: 'Firma', style: 'signText' },
    //           ],
    //           [
    //             {},
    //             { text: 'Aclaración', style: 'signText' },
    //             { text: '', border: [false, false, false, false] },
    //             { text: 'Aclaración', style: 'signText' },
    //           ],
    //         ],
    //       },

    //       layout: {
    //         hLineWidth: function (i: any, node: any) {
    //           if (i === node.table.body.length) {
    //             return 0; // Sin línea arriba del header
    //           }
    //           return 0.5; // Líneas finas debajo de los demás rows
    //         },
    //         hLineStyle: function (i: any, node: any) {
    //           if (i === 0) {
    //             return null;
    //           }
    //           return { dash: { length: 2, space: 2 } };
    //         },
    //         vLineWidth: function (i: any, node: any) {
    //           return 0; // Sin otras líneas verticales
    //         },
    //         hLineColor: function (i: any, node: any) {
    //           if (i === 0) {
    //             return '#000000'; // Línea negra debajo del header
    //           }
    //           return '#808080'; // Líneas finas y grises debajo de los demás rows
    //         },
    //       },
    //     },
    //   ],
    //   styles: {
    //     dateAndLogoTable: {
    //       margin: [0, 0, 0, 10],
    //     },
    //     receiptNumber: {
    //       fontSize: 12,
    //     },
    //     receiptDate: {
    //       fontSize: 11,
    //       bold: true,
    //     },
    //     clientDataTable: {
    //       margin: [0, 0, 0, 25],
    //     },
    //     tableHeader: {
    //       fontSize: 10,
    //       bold: true,
    //       margin: [0, 2],
    //     },
    //     signTable: {
    //       margin: [0, 10],
    //     },
    //     tableData: {
    //       fontSize: 10,
    //       bold: false,
    //       margin: [0, 2],
    //     },
    //     signText: {
    //       fontSize: 8,
    //       color: '#808080',
    //       bold: true,
    //       alignment: 'center',
    //     },
    //   },
    // };

    pdfMake.createPdf(pdfDoc).open();
  }

  generateServicesRows(services: any) {
    let rows: Array<any> = []
    services.forEach((service: any) => {
      let row: Array<any> = [
        {
          text: service.name,
          style: 'tableData'
        },
        {
          text: service.quantity,
          style: 'tableData',
          alignment: 'right'
        }
      ]
      rows.push(row)
    });
    return rows
  }

  //en esta función se genera el dato del número de orden de entrega
  generateDeliveryReceiptNumber(number: number) {
    let stringNumber = '0000-00000000'; //esto no es dinámico
    const numberString = number.toString();

    const numLength = numberString.length;

    const startIndex = stringNumber.length - numLength;

    const startPart = stringNumber.slice(0, startIndex);

    const result = startPart + numberString;

    return result;
  }

  //genera una fecha en string
  secondsToFormattedDate(seconds: number): string {
    const date = dayjs.unix(seconds);
    const formattedDate = date.format('DD/MM/YY');
    return formattedDate;
  }

  //formatea la imagen para el pdf
  getBase64ImageFromURL(url: any): any {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');

      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0);

        const dataURL = canvas.toDataURL('image/svg');

        resolve(dataURL);
      };

      img.onerror = (error) => {
        reject(error);
      };

      img.src = url;
    });
  }

  //se genera el array de la tabla de items apta para pdfMake
  async generateMenusTableData(viandas: any) {
    let orderData: Array<any> = [];
    let tableRows = 20; //cantidad de rows hardcodeado para que el footer quede al final de la página
    viandas.forEach((vianda: any) => {
      let viandaData = []
      // let row = [
      //   { text: data.length === 0 ? requester : '', style: 'tableData' },
      //   { text: menu.typeMenu.menu_name, style: 'tableData' },
      //   { text: menu.quantity.toString(), style: 'tableData' },
      //   { text: drinks[i] ? drinks[i].drink.name : '', style: 'tableData' },
      //   {
      //     text: drinks[i] ? drinks[i].quantity.toString() : '',
      //     style: 'tableData',
      //   },
      // ];
      let requester = vianda.requested_to.first_names + ' ' + vianda.requested_to.last_names
      let i = 0;
      while (i < vianda.menus_requested.length || i < vianda.drinks_requested.length) {
        let row: Array<any> = [
          { text: viandaData.length === 0 ? requester : '', style: 'tableData' },
          { text: vianda.menus_requested[i] ? vianda.menus_requested[i].typeMenu.menu_name : '', style: 'tableData' },
          { text: vianda.menus_requested[i] ? vianda.menus_requested[i].quantity.toString() : '', style: 'tableData' },
          { text: vianda.drinks_requested[i] ? vianda.drinks_requested[i].drink.name : '', style: 'tableData' },
          { text: vianda.drinks_requested[i] ? vianda.drinks_requested[i].quantity.toString() : '', style: 'tableData' },
        ];
        viandaData.push(row);
        i++
      }
      orderData = orderData.concat(viandaData)
    });

    if (orderData.length < tableRows) {
      orderData = this.generateEmptyRows(orderData, tableRows - orderData.length, 5);
    }

    return orderData;
  }

  //completa con rows vacíos para que la tabla tenga siempre el mismo tamaño
  generateEmptyRows(
    tableData: Array<any>,
    iterations: number,
    columns: number
  ) {
    let row: Array<any> = [];
    let i = 0;
    while (i < columns) {
      row.push({ text: '', style: 'tableData' });
      i++;
    }
    for (var j = 0; j < iterations; j++) {
      tableData.push(row);
    }
    return tableData;
  }
}
