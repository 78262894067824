/* import { Routes } from '@angular/router';
import { MobileGuard } from './guards/mobile.guard';

export const routes: Routes = [
     // { path: '', redirectTo: 'clientes', pathMatch: 'full' },
  { 
    path: '', 
    canActivate: [MobileGuard],
    children: [
      { path: '', redirectTo: 'clientes', pathMatch: 'full' },
      // otras rutas aquí
    ]
  },
  {
    path: 'clientes',
    
    loadChildren: () => import('./pages/customers/customers.module').then(m => m.CustomersModule)
  },
  {
    path: 'viandas',
    loadChildren: () => import('./pages/viandas/viandas.module').then(m => m.ViandasModule)
  },
  {
    path: 'tareas',
    loadChildren: () => import('./pages/tasks/tasks.module').then(m => m.TasksModule)
  },
  {
    path: 'eventos',
    loadChildren: () => import('./pages/events/events.module').then(m => m.EventsModule)
  },
  {
    path: 'chat',
    loadChildren: () => import('./pages/chat/chat.module').then(m => m.ChatModule)
  },
  {
    path: 'menus',
    loadChildren: () => import('./pages/menus/menus.module').then(m => m.MenusModule)
  },
  {
    path: 'bebidas',
    loadChildren: () => import('./pages/drinks/drinks.module').then(m => m.DrinksModule)
  },
  {
    path: 'servicios',
    loadChildren: () => import('./pages/services-provided/services-provided.module').then(m => m.ServicesProvidedModule)
  },
  {
    path: 'eventos-config',
    loadChildren: () => import('./pages/events-config/events-config.module').then(m => m.EventsConfigModule)
  },
  {
    path: 'menus-especiales',
    loadChildren: () => import('./pages/special-menus/special-menus.module').then(m => m.SpecialMenusModule)
  },
  {
    path: 'usuarios',
    loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'accesos',
    loadChildren: () => import('./pages/access/access.module').then(m => m.AccessModule)
  },
  {
    path: 'consumos',
    loadChildren: () => import('./pages/consumption/consumption.module').then(m => m.ConsumptionModule)
  },
  {
    path: 'errores-registros',
    loadChildren: () => import('./pages/register-error/register-error.module').then(m => m.RegisterErrorModule)
  },
  {
    path: 'repartidor',
    loadChildren: () => import('./pages/delivery/delivery.module').then(m => m.DeliveryModule),
  },
];
 */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { redirectUnauthorizedTo, redirectLoggedInTo, canActivate } from '@angular/fire/compat/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['auth']);
const redirectLoggedInToLayout = () => redirectLoggedInTo(['']);

export const routes: Routes = [
  {
    path: '', loadChildren: () => import('../app/layout/layout.module').then(m => m.LayoutModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'auth', loadChildren: () => import('./pages/authentication/authentication.module').then(m => m.AuthenticationModule),
    ...canActivate(redirectLoggedInToLayout)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
