import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { StockCategoryCard } from '../../interfaces/stock-category-card.interface';

@Component({
  selector: 'butaco-stock-category-card',
  templateUrl: './stock-category-card.component.html',
  styleUrls: ['./stock-category-card.component.scss']
})
export class StockCategoryCardComponent implements OnInit {

  @Output() changeSelectedCard = new EventEmitter();
  @Input() category!: StockCategoryCard;
  @Input() idCategorySelected!: number;
  @Input() supervisor!: boolean;
  @Input() statusRequest!: string;

  constructor() { }

  onSelectCard(cat: any): void {
    this.changeSelectedCard.emit(cat);
  }

  ngOnInit(): void {
    // console.log(this.category);
  }
}
