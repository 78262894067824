import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { DeliveryService } from '../../services/delivery.service';
import { ConsumptionService } from '../../services/consumption.service';
import { OrderedViandasQuantityPipe } from '../../pipes/ordered-viandas-quantity.pipe';
import { OrderedDrinksQuantityPipe } from '../../pipes/ordered-drinks-quantity.pipe';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'butaco-delivery-info-mobile-dialog',
  templateUrl: './delivery-info-mobile-dialog.component.html',
  styleUrls: ['./delivery-info-mobile-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    OrderedViandasQuantityPipe,
    OrderedDrinksQuantityPipe,
    MatDialogModule
  ],
})
export class DeliveryInfoMobileDialogComponent implements OnInit {
  loading = true;
  deliveryOrder: any;

  columnsToDisplay = ['Empresa', 'Ubicacion', 'Viandas', 'Bebidas'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private deliveryServ: DeliveryService,
    private consumptionServ: ConsumptionService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    console.log(this.data);
    this.setData();
  }

  setData() {
    this.deliveryOrder = this.data;
    this.loading = false;
  }
}
