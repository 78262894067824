import { Pipe, PipeTransform } from '@angular/core';
import { DeliveryItem, DeliveryOrder } from '../../models/delivery_orders.model';
import { Menu } from '../interfaces/menu-interface';
import { Menus_Requested } from '../../models/viandas';

@Pipe({
  name: 'orderedViandasQuantity',
  standalone: true
})
export class OrderedViandasQuantityPipe implements PipeTransform {

  transform(value: Menus_Requested[], tableType: string): any {
    let viandasArray = [];
    let viandas = 0;
    if (tableType === 'tablaChica'){
      let viandas:Array<any> = [];
      if (value.length != 0){
        value.forEach(menu=>{
          if(menu.quantity != 0){
            viandas.push(menu)
          }
        })
        return viandas
      } else {
        let quantity = 0;
        let typeMenu = {
          name: '',
          menu_name: '-'
        }
        viandasArray = [{quantity, typeMenu}]
        return viandasArray
      }
    } else if (tableType === 'tablaGrande') {
      value.forEach(menu => {
        if (menu.typeMenu.component === 'Principal'){
          viandas=viandas+ menu.quantity;
        }
      });
      return viandas
    }
    return
  }

}
