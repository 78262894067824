import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { FocusControlDirective } from './directives/focus-control.directive';
import { StockCategoryCardComponent } from './components/stock-category-card/stock-category-card.component';
import { WomanShoppingSvgComponent } from './components/svg/woman-shopping-svg/woman-shopping-svg.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorConfirmandoDialogComponent } from '../pages/viandas/components/entregas/pedidos-entrega-listos/error-confirmando-dialog/error-confirmando-dialog.component';
import { MobileDeliveryCardsComponent } from './components/cards/mobile-delivery-cards/mobile-delivery-cards.component';



@NgModule({
    declarations: [
        FocusControlDirective,
        StockCategoryCardComponent,
        WomanShoppingSvgComponent,
        ErrorConfirmandoDialogComponent,
    ],
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        MobileDeliveryCardsComponent
    ],
    exports: [
        MaterialModule,
        StockCategoryCardComponent,
        WomanShoppingSvgComponent,
        MobileDeliveryCardsComponent
    ]
})
export class SharedModule { }
