<div *ngIf="error then error; else noError"></div>

<ng-template #error>
  <mat-dialog-content>
    <div class="example-container">
      <h1>Error confirmando entrega</h1>
      <p>Revise si hay un comprobante cargado</p>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-dialog-close mat-stroked-button color="accent">CERRAR</button>
  </mat-dialog-actions>
</ng-template>


<ng-template #noError>
  <mat-dialog-content>
    <div class="example-container">
      <h1>Entrega confirmada</h1>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-dialog-close mat-stroked-button color="accent">CERRAR</button>
  </mat-dialog-actions>
</ng-template>
