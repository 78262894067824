import { Pipe, PipeTransform } from '@angular/core';
import { table } from 'console';
import { Drinks_requested } from '../../models/viandas';

@Pipe({
  name: 'orderedDrinksQuantity',
  standalone: true
})
export class OrderedDrinksQuantityPipe implements PipeTransform {

  transform(value: Drinks_requested[], tableType: string): any {
    let drinksArray = [];
    let drinks=0;
    if(tableType === 'tablaChica'){
      if (value.length != 0){
        return value
      } else {
        let quantity = 0;
        let drink = {
          name: '',
          content: ''
        }
        drinksArray = [{quantity, drink}]
        return (drinksArray)
      }
    } else if(tableType === 'tablaGrande'){
      value.forEach(drink => {
        drinks=drinks+ drink.quantity;
    });
      return drinks
    }

  }

}
