import { CommonModule, NgForOf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import dayjs from 'dayjs';
import { DeliveryInfoMobileDialogComponent } from '../../../../shared/dialogs/delivery-info-mobile-dialog/delivery-info-mobile-dialog.component';

@Component({
  selector: 'butaco-mobile-delivery-cards',
  templateUrl: './mobile-delivery-cards.component.html',
  styleUrls: ['./mobile-delivery-cards.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    NgForOf,
    MatExpansionModule,
    MatDividerModule,
    MatIconModule
  ],
})
export class MobileDeliveryCardsComponent implements OnInit {
  deliveryOrder:any;

  menuActions:any;

  @Input() set delivery(data: any) {
    this.deliveryOrder = data;
  }

  @Input() set actions(data: any) {
    this.menuActions = data;
  }

  @Output() functionSelected = new EventEmitter<any>();

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {
  }

  handleButtonClick(functionName: string, event: MouseEvent) {
    if (functionName !== 'openDetails') {
      event.stopPropagation();
    }

    this.onButtonClick(functionName);
  }

  onButtonClick(action:any) {
    console.log(action);
    this.functionSelected.emit({ function: action, data: this.deliveryOrder });
  }

  viewData() {
    const dialogRef = this.dialog.open(DeliveryInfoMobileDialogComponent, {
      data: this.deliveryOrder,
    });
  }


  //Datos de la tabla
  totalDrinks(): number {
    if (!this.deliveryOrder?.drinks_requested) {
      return 0;
    }
    return this.deliveryOrder.drinks_requested.reduce((total:any, drink:any) => total + drink.quantity, 0);
  }

  totalPrincipal(): number {
    if (!this.deliveryOrder?.requested_menus) {
      return 0;
    }
    return this.deliveryOrder.requested_menus
      .filter((menu:any) => menu.typeMenu.component === 'Principal')
      .reduce((total:any, menu:any) => total + menu.quantity, 0);
  }

  secondsToFormattedDate(): string {
    const date = dayjs.unix(this.deliveryOrder.delivered_date.seconds);
    const formattedDate = date.format('DD/MM/YYYY');
    return formattedDate;
  }

  generateDeliveryReceiptNumber(number: number) {
    let stringNumber = '0000-00000000' 
    const numberString = number.toString();
    const numLength = numberString.length;
    const startIndex = stringNumber.length - numLength;
    const startPart = stringNumber.slice(0, startIndex);
    const result = startPart + numberString;
    return result;
  }
}
