<div class="container">
    <div class="title">
        {{deliveryOrder.companyName}}
    </div>
    <div class="location">

        {{deliveryOrder.location}} - {{deliveryOrder.address}}

    </div>
    <div class="delivery-quantity">
        <div class="data">
            Viandas - {{deliveryOrder.viandas_quantity}}
        </div>
        <div class="data">
            Bebidas -  {{deliveryOrder.drinks_quantity}}
        </div>
    </div>
    <!-- <table>
        <td>
            <tr class="header">
                Empresa
            </tr>
            <tr class="data">
                {{deliveryOrder.companyName}}
            </tr>


        </td>
        <td>
            <tr class="header">
                Ubicación
            <tr class="data">
                {{deliveryOrder.location}}
            </tr>
 
        </td>
        <td>
            <tr class="header">
                Dirección
            </tr>
            <tr class="data">
                {{deliveryOrder.address}}
            </tr>
        </td>
    </table> -->
    <table class="d-v-md-t viandaTable">
        <tr>
            <td class="header">Menus</td>
            <td class="header ">Tipo</td>
            <td class="header">Cant</td>
        </tr>
        <tr *ngFor="
      let menu of deliveryOrder.vianda.menus
        | orderedViandasQuantity : 'tablaChica'
    ">
            <td class="data">
                <div class="menu-column">
                    <p class="menuTitle">{{ menu.typeMenu.menu_name }}</p>
                </div>
            </td>
            <td class="data">{{ menu.typeMenu.name }}</td>
            <td class="data" id="vianda-quantity">{{ menu.quantity }}</td>
        </tr>
        <tr>
            <td class="header">Bebidas</td>
            <td class="header">&nbsp;</td>
            <td class="header">&nbsp;</td>
        </tr>
        <tr *ngFor="
      let drink of deliveryOrder.vianda.drinks
        | orderedDrinksQuantity : 'tablaChica'
    ">
            <td class="data">
                <div>
        <tr>
            {{
            drink.drink.name
            }}
            -
            {{
            drink.drink.content
            }}
        </tr>
</div>
</td>
<td class="data">&nbsp;</td>
<td class="data" id="vianda-quantity">
    {{ drink.quantity }}
</td>
</tr>
</table>
<table class="d-r-md-t receiptTable">
    <td>
        <tr class="header">
            Comprobantes entrega
        </tr>
        <tr class="data" *ngIf="deliveryOrder.delivered_receipt !== ''">
            {{
            deliveryOrder.delivered_receipt
            }}
        </tr>
        <tr class="data" *ngIf="deliveryOrder.delivered_receipt === ''">
            -
        </tr>
    </td>
    <td>
        <tr class="header">
            Entrego
        </tr>
        <tr class="data" *ngIf="deliveryOrder.delivered_by">
            {{
            deliveryOrder.delivered_by.first_names
            }} {{
            deliveryOrder.delivered_by.last_names
            }}
        </tr>
        <tr class="data" *ngIf="deliveryOrder.delivered_by === ''">
            -
        </tr>
    </td>
</table>
</div>