export enum Services {
    Hoteleria = "Hoteleria",
    Lavanderia = "Lavanderia",
    Viandas = "Viandas",
    Suministros = "Suministros",
    Transporte = "Transporte"
}

export enum UserAccessType {
    Operativo = "Operativo",
    Administrativo = "Administrativo",
    Admin = "Admin",
}

export enum UserStatus {
    Active = "Active",
    Inactive = "Inactive",
    Pending = "Pending",
}

export enum UserOperativeRoles {
    Responsable = "Responsable",
    Final = "Final"
}

export enum UserAdminstrativeRoles {
    Facturacion = "Factuiracion",
    Consumo = "Consumo"
}

export enum UserAdminRoles {
    AdministradorUsuarios = "Usuario Responsable"
}

export enum UserInternalRoles {
    SysAdmin = "Sys Admin",
    Admin = "Admin",
    AdminAdministrativo = "Admin Administrativo",
    AdminOperativo = "Admin Operativo",
    Cocina = "Cocina",
    Campo = "Campo",
    Ventas = "Ventas",
    Administracion = "Administracion",
    Eventos = "Eventos",
    Seguridad = "Seguridad",
    Transporte = "Transporte"
}

export enum AccessScreens {
    Clientes = "Clientes",
    Viandas = "Viandas",
    Tareas = "Tareas",
    Eventos = "Eventos",
    Usuarios = "Usuarios",
    ChatInterno = "Chat Interno",
    ConfigMenus = "Config Menus",
    ConfigMenusEspeciales = "Config Menus Especiales",
    ConfigBebidas = "Config Bebidas",
    ConfigServicios = "Config Servicios",
    ConfigEventos = "Config Eventos",
}

export enum deliveryStatus {
    Pendiente = 'pendiente',
    Preparando = "preparando",
    Empaquetando = "empaquetando",
    Viajando = "viajando",
    Entregado = "entregado",
    Cancelado = "cancelado"
}

//hacer vianda status 
export enum viandaStatus {
    Cancelado = "cancelled",
    Aceptado = "accepted",
    AprobacionPendiente = "pending",
    CancelacionPendiente = "pending cancellation",
}