<div *ngIf="supervisor; then supervisorCard else controlStock"></div>

<ng-template #controlStock>
  <div (click)="onSelectCard(category)"
    [ngClass]="{'selected-card': idCategorySelected.toString() === category.cat_id, 'verified-card': category.verifiedArticles?.length === category.cat_count}"
    class="stock-check-card">
    <h1>{{category.cat_name}}</h1>
    <div class="card-body">
      <span>Artículos verificados:</span>
      <div style="flex: 1 1 auto"></div>
      <span class="items-counter"> {{category.verifiedArticles?.length}}/{{category.cat_count}} </span>
    </div>
  </div>
</ng-template>

<ng-template #supervisorCard>
  <div (click)="onSelectCard(category)" [ngClass]="{'selected-card': idCategorySelected.toString() === category.cat_id, 'verified-card': category.verifiedArticles?.length === category.cat_count,
                  'to-control-card': category.item_count === 0}" class="stock-check-card">
    <h1>{{category.cat_name}}</h1>
    <div class="card-body">
      <span>Artículos verificados:</span>
      <div style="flex: 1 1 auto"></div>
      <span class="items-counter" *ngIf="statusRequest !== 'Completo'">
        {{category.verifiedArticles?.length}}/{{category.item_count}} </span>
    </div>
  </div>
</ng-template>