<div class="card-container">
  <mat-expansion-panel
    #menuDetail
    hideToggle
    class="card"
    [ngClass]="{ 'pending-signature': deliveryOrder.status === 'noSignature' }"
  >
    <mat-expansion-panel-header
      class="expansion-panel-header"
      [collapsedHeight]="'110px'"
      [expandedHeight]="'110px'"
    >
      <div class="container">
        <div class="row">
          <div class="header-info">
            <div class="header numberOrder">
              ORDEN DE ENTREGA N°
              {{ generateDeliveryReceiptNumber(deliveryOrder.number) }}
            </div>
            <div class="company-name">
              <p class="header">Empresa</p>
              <p class="info">{{ deliveryOrder.companyName }}</p>
            </div>
          </div>
          <div class="items">
            <div
              class="quantity"
              [ngClass]="{
                'pending-signature': deliveryOrder.status === 'noSignature'
              }"
            >
              <mat-icon
                fontSet="material-icons-outlined"
                [color]="
                  deliveryOrder.status !== 'noSignature' ? 'primary' : ''
                "
                class="icon-sm vianda-icon"
                >fastfood</mat-icon
              >
              <span
                class="quantity"
                [ngClass]="{
                  'pending-signature': deliveryOrder.status === 'noSignature'
                }"
                >{{ deliveryOrder.viandas_quantity }}</span
              >
            </div>
            <span *ngIf="deliveryOrder.status === 'entregado'" class="signature"
              >Firmado</span
            >
            <span
              *ngIf="deliveryOrder.status === 'noSignature'"
              class="pending-signature"
              >Pendiente de firma</span
            >
          </div>
        </div>
        <div class="row">
          <div class="location">
            <p class="header">Ubicación</p>
            <p class="info">{{ deliveryOrder.location }}</p>
          </div>
          <div>
            <button
              *ngIf="
                !menuDetail.expanded && menuActions[0].buttonType !== 'icon'
              "
              mat-flat-button
              color="{{ menuActions[0].color }}"
              (click)="handleButtonClick(menuActions[0].functionName, $event)"
            >
              {{ menuActions[0].message }}
            </button>
            <button
              *ngIf="
                !menuDetail.expanded &&
                menuActions[0].buttonType === 'icon' &&
                menuActions[0].icon
              "
              mat-icon-button
              [color]="menuActions[0].color"
              (click)="handleButtonClick(menuActions[0].functionName, $event)"
            >
              <mat-icon>{{ menuActions[0].icon }}</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>
    <!-- PARTE EXPANDIBLE -->
    <div class="menus">
      <div class="header">Menús</div>
      <div class="header">Cant</div>
      <ng-container *ngFor="let menu of deliveryOrder.menus_requested">
        <div class="rows">{{ menu.typeMenu.name }}</div>
        <div class="rows cant">{{ menu.quantity }}</div>
      </ng-container>
      <div class="rows">Bebidas</div>
      <div class="rows cant">{{deliveryOrder.drinks_quantity}}</div>
      <div class="header">Total</div>
      <div class="header cant">{{deliveryOrder.menus_quantity}}</div>
    </div>
    <mat-divider></mat-divider>
    <div>
      <div class="menus">
        <div class="header">Entrego</div>
        <div class="header">Fecha</div>
        <div class="rows">
          {{ deliveryOrder.delivered_by.first_names }}
          {{ deliveryOrder.delivered_by.last_names }}
        </div>
        <div class="rows">{{ secondsToFormattedDate() }}</div>
      </div>
      <div class="header">Firma</div>
      <div *ngIf="!deliveryOrder.delivered_receipt" class="rows signature">
        No se han adjuntado archivos todavía.
      </div>
    </div>
    <div class="expandable-actions">
      <ng-container *ngFor="let action of menuActions.slice(1)">
        <button
          *ngIf="action.buttonType === 'raised'"
          mat-raised-button
          [color]="action.color"
          (click)="handleButtonClick(action.functionName, $event)"
        >
          {{ action.message }}
        </button>
        <button
          *ngIf="action.buttonType === 'flat'"
          mat-flat-button
          [color]="action.color"
          (click)="handleButtonClick(action.functionName, $event)"
        >
          {{ action.message }}
        </button>
        <button
          *ngIf="action.buttonType === 'stroked'"
          mat-stroked-button
          [color]="action.color"
          (click)="handleButtonClick(action.functionName, $event)"
        >
          {{ action.message }}
        </button>
      </ng-container>
    </div>
    <!-- FIN PARTE EXPANDIBLE -->
  </mat-expansion-panel>
</div>
