import { Injectable } from '@angular/core';

import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { viandaStatus } from '../../../../src/app/models/enums';
import { Pedido } from '../../../../src/app/models/viandas';
import dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
export class ViandasService {
  private mealsOrderedRef: AngularFirestoreCollection<any>;
  private menusCreatedCollection: AngularFirestoreCollection<any>;
  private contractsCollection: AngularFirestoreCollection<any>;

  constructor(private afs: AngularFirestore) {
    this.menusCreatedCollection = this.afs.collection<any>('menus_created');
    this.mealsOrderedRef = this.afs.collection<any>('ordered_viandas');
    this.contractsCollection = this.afs.collection<any>('contracts');
  }

  getAllViandas(): Observable<any> {
    return this.afs
      .collection('delivery_orders')
      .valueChanges({ idField: 'id' });
  }

  getOrdersDate(date: any): Observable<any> {
    const dateEnd = dayjs(date).endOf('day').toDate();
    return this.afs
      .collection('ordered_viandas', (ref) =>
        ref.where('date_meal', '>=', date).where('date_meal', '<=', dateEnd)
      )
      .valueChanges({ idField: 'id' });
  }

  getOrderByDeliveryId(deliveryId: any) {
    return this.afs
      .collection('ordered_viandas', (ref) =>
        ref.where('delivery_order_id', '==', deliveryId)
      )
      .valueChanges({ idField: 'id' });
  }
  /**
   * Returns the menu for a specific date
   * @param date date in format 'YYYMMDD'
   * @returns Observable
   */
  getMenuForSingleDate(date: string): Observable<any> {
    return this.afs
      .collection('menus_created')
      .doc(date)
      .valueChanges({ idField: 'id' });
  }

  getMenusBetweenDates(dateFrom: Date, dateTo: Date): Observable<any> {
    return this.afs
      .collection('menus_created', (ref) =>
        ref.where('date', '>=', dateFrom).where('date', '<=', dateTo)
      )
      .valueChanges({ idField: 'id' });
  }

  orderMeal(mealOrdered: any): Promise<any> {
    return this.mealsOrderedRef.add(mealOrdered);
  }

  orderMealSaveChange(orderId: string, mealOrdered: any): Promise<any> {
    return this.mealsOrderedRef.doc(orderId).set(mealOrdered);
  }

  getRequestMeals(
    dateFrom: Date,
    dateTo: Date,
    companyId: string
  ): Observable<any> {
    return this.afs
      .collection('meals_ordered', (ref) =>
        ref
          .where('date_Meal', '>=', dateFrom)
          .where('date_Meal', '<=', dateTo)
          .where('company.companyId', '==', companyId)
      )
      .valueChanges({ idField: 'id' });
  }

  getRequestMealsByLocation(
    dateFrom: Date,
    dateTo: Date,
    locationId: string
  ): Observable<any> {
    return this.afs
      .collection('meals_ordered', (ref) =>
        ref
          .where('date_Meal', '>=', dateFrom)
          .where('date_Meal', '<=', dateTo)
          .where('location.location.id', '==', locationId)
      )
      .valueChanges({ idField: 'id' });
  }

  //TODO: Change location.location in objext so we can use only one call
  getPersonalRequestMealsByLocation(
    dateFrom: Date,
    dateTo: Date,
    locationId: string
  ): Observable<any> {
    return this.afs
      .collection('meals_ordered', (ref) =>
        ref
          .where('date_Meal', '>=', dateFrom)
          .where('date_Meal', '<=', dateTo)
          .where('location.id', '==', locationId)
      )
      .valueChanges({ idField: 'id' });
  }

  //Agregados
  getOrderById(idX: any): Observable<any> {
    return this.afs.collection('ordered_viandas').doc(idX).valueChanges();
  }

  /**
   * Guarda una modificación de pedido de vianda con el nuevo status_request
   * @param order Pedido
   * @param action ViandaStatus
   * @returns Success or Error
   */
  async changeStatusOrderRequest(
    order: Pedido,
    action: viandaStatus
  ): Promise<any> {
    if (order?.id) {
      const newOrder = order;
      newOrder.status_request = action;
      return this.orderMealSaveChange(order.id, newOrder);
    }
  }

  async changeStatusCancelledOrderByButacoAdmin(
    id: any,
    state: any,
    date: any
  ): Promise<any> {
    let changePendingOrder: any;
    changePendingOrder = await this.afs
      .collection('ordered_viandas')
      .doc(id)
      .ref.get();
    changePendingOrder = changePendingOrder.data();
    changePendingOrder.status_request = state;
    changePendingOrder.date_request = date;
    return this.orderMealSaveChange(id, changePendingOrder);
  }

  getAllContracts(): Observable<any> {
    return this.afs.collection('contracts').valueChanges({ idField: 'id' });
  }

  getAllDrinks(): Observable<any> {
    return this.afs.collection('drinks').valueChanges({ idField: 'id' });
  }

  // Función que compara la fecha actual con la fecha del pedido y unas condiciones para fijarse si el usuario puede editar el pedido
  checkEditPermissions(requestTimestamp: any) {
    let currentDate = dayjs(); // Obtiene la fecha actual
    let dateRequest = dayjs(new Date(requestTimestamp.seconds * 1000));
    let permissions = {
      userCanEdit: true,
      butacoCanEdit: true,
    };
    // if (currentDate.startOf('day').isBefore(dateRequest.startOf('day'))) {
    //   if (dateRequest.day() === 1) {
    //     if (currentDate.day() === 6 && currentDate.hour() < 6) { // Si hoy es sábado
    //       permissions.butacoCanEdit = true;
    //       permissions.userCanEdit = true;
    //     } else if (currentDate.day() === 1 && currentDate.hour() < 10) { // Si hoy es lunes
    //       permissions.butacoCanEdit = true;
    //     }
    //   } else if (dateRequest.day() > 1 && dateRequest.day() < 6) {
    //     if (dateRequest.day() > currentDate.day() || (dateRequest.day() < currentDate.day() && currentDate.hour() < 10)) { // Si hoy es martes a viernes
    //       permissions.butacoCanEdit = true;
    //       permissions.userCanEdit = true;
    //     } else if (currentDate.day() === dateRequest.day() && currentDate.hour() < 10) {
    //       permissions.butacoCanEdit = true;
    //     }
    //   } else {
    //     console.log('pedido para sabado')
    //     if ((currentDate.day() === 5 && currentDate.hour() < 6) || currentDate.day() < 5) { // Si hoy es viernes
    //       console.log('hoy es miercoles')
    //       permissions.butacoCanEdit = true;
    //       permissions.userCanEdit = true;
    //     } else if (currentDate.day() === 5 && currentDate.hour() < 9) {
    //       permissions.butacoCanEdit = true;
    //     }
    //   }
    // }

    return permissions;
  }
}
